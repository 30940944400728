import { observable, configure, action, computed } from 'mobx';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class TripletexStore {
    constructor() {

    }

    @action
    updateKeyValue(key, value) {
        this[key] = value;
    }

    @action
    updateObjectKeyValue(obj, key, value) {
        this[obj][key] = value;
    }

    @observable redirectUrl = null;


    async getSessionToken() {
        const response = await util.fetchApi(`/api/integrations/tripletex/session-token/create`);
        switch (response.status) {
            case 200:
                // console.log('tripletexStore.getSessionToken', response);
                return response;
            default:
                console.error('tripletexStore.getSessionToken', response);
                return response;
        }
    }

    async getCustomers() {
        const response = await util.fetchApi(`/api/integrations/tripletex/customers`);
        switch (response.status) {
            case 200:
                // console.log('tripletexStore.getCustomers', response);
                return response;
            default:
                console.error('tripletexStore.getCustomers', response);
                return response;
        }
    }

    async getInvoices() {
        const response = await util.fetchApi(`/api/integrations/tripletex/invoices`);
        switch (response.status) {
            case 200:
                // console.log('tripletexStore.getInvoices', response);
                return response;
            default:
                console.error('tripletexStore.getInvoices', response);
                return response;
        }
    }

    async createInvoice(data) {
        const response = await util.fetchApi(`/api/integrations/tripletex/invoices`, {
            method: 'POST'
        },{
            // "customer": {
            //   "id": 13158013
            // },
            "invoiceDate": "2024-09-30",
            "invoiceDueDate": "2024-10-15",
            // "dueDate": "2024-10-15",
            // "currency": "NOK",
            "orders": [
                {
                    "customer": {
                        "id": 13158013,
                        "name": "Raske Poter AS",
                    },
                    "orderDate": "2024-09-30",
                    "deliveryDate": "2024-09-30",

                    "orderLines": [
                        {
                            "description": "Consulting Services",
                            "count": 10,
                            "unitPriceExcludingVatCurrency": 1000,
                            "vatType": {
                                "percentage": 25,
                            }
                        }
                    ],

                },
            ],

            // "orderNumber": "INV-2024-0001",
            // "deliveryDate": "2024-09-29",
            // "kid": "123456789"
        });

        switch (response.status) {
            case 200:
                // console.log('tripletexStore.createInvoice', response);
                return response;
            default:
                console.error('tripletexStore.createInvoice', response);
                return response;
        }
    }

    async createCustomer(data) {
        const response = await util.fetchApi(`/api/integrations/tripletex/customers`, {
            method: 'POST'
        },{
            "name": "Raske Poter AS",
            "postalAddress": {
                "addressLine1": "Kongens gate 1",
                "postalCode": "7011",
                "city": "Trondheim",
            },
            "email": "post@raskepoter.com",
            "phoneNumber": "12345678",
            "organizationNumber": "123456789",
            "customerNumber": "123456789",
            "accountManager": {
                "firstName": "Ola",
                "lastName": "Nordmann",
                "phoneNumberMobile": "12345678",
                "email": "test@test.com",
            },
        });

        switch (response.status) {
            case 200:
                // console.log('tripletexStore.createCustomer', response);
                return response;
            default:
                console.error('tripletexStore.createCustomer', response);
                return response;
        }
    }
}

const store = new TripletexStore();
export default store;
