import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalJournalListMini from '../../components/dyrejournal/listJournalMini';


@withText(props => ({
    newJournalAreYouSure: <Text id='dyrejournal.new-journal-are-you-sure'>Are you sure you want to create a new journal?</Text>,
}))
@observer
class AnimalListMini extends Component {
	constructor(props) {
        super(props);
        this.state = {
            smsSending: {},
            smsSent: {},
            checkingWithDyreId: {},
            sendingToDyreId: {},
            sentToDyreId: {},
            dyreIdResponse: {},
            updatingAnimal: {},
            updatedAnimal: {},
        };
    }

    onClickViewAnimal = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.animal-line').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore, animalStore } = this.props.stores;
		const { drawerHeightLarge } = appState;

		appState.openDrawer('viewAnimal', {
			height: drawerHeightLarge,
            isNew: false,
            hideAdmin: true,
            id: parseInt(id, 10),
            callback: () => {
                // console.log('onClickViewAnimal callback');
                const { animal } = animalStore;
                const { newSale, visitorAnimals } = saleStore;
                const { animals } = newSale;
                const idx = animals.findIndex(a => a.id === animal.id);
                if (idx > -1) {
                    saleStore.updateObjectKeyArrValue('newSale', 'animals', idx, animal);
                }
                const idx2 = visitorAnimals.findIndex(a => a.id === animal.id);
                if (idx2 > -1) {
                    saleStore.updateKeyArrValue('visitorAnimals', idx2, animal);
                }
            },
		}, drawerLevel + 1);
    }

    onClickEditAnimal = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.animal-line').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore, animalStore } = this.props.stores;
		const { drawerHeightLarge } = appState;

		appState.openDrawer('editAnimal', {
			height: drawerHeightLarge,
            isNew: false,
            hideAdmin: true,
            id: parseInt(id, 10),
            callback: () => {
                // console.log('onClickEditAnimal callback');
                const { animal } = animalStore;
                const { newSale, visitorAnimals } = saleStore;
                const { animals } = newSale;
                const idx = animals.findIndex(a => a.id === animal.id);
                if (idx > -1) {
                    saleStore.updateObjectKeyArrValue('newSale', 'animals', idx, animal);
                }
                const idx2 = visitorAnimals.findIndex(a => a.id === animal.id);
                if (idx2 > -1) {
                    saleStore.updateKeyArrValue('visitorAnimals', idx2, animal);
                }
            },
		}, drawerLevel + 1);
    }

    onClickRemoveAnimal = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('div').dataset;
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;
        const { animals } = newSale;
        const newAnimals = animals.filter(v => v.id !== parseInt(id, 10));
        saleStore.updateObjectKeyValue('newSale', 'animals', newAnimals);
    }

    saveJournal = async e => {
        const { journalStore } = this.props.stores;
        const { newJournal } = journalStore;

        if (newJournal.id) {
            await journalStore.save(newJournal);
        } else {
            const response = await journalStore.insert(newJournal);
            // Update current newJournal object
            const journal = response?.data?.journal;
            journalStore.updateObjectKeyValue('newJournal', 'id', journal?.id);
            journalStore.updateObjectKeyValue('newJournal', 'journalNumber', journal?.journalNumber);
        }
        return true;
    }

    onClickNewJournal = async e => {
        const {
            newJournalAreYouSure,
            animals = [],
        } = this.props;
        e.stopPropagation();
        e.preventDefault();

        if (!confirm(newJournalAreYouSure)) {
            return;
        }

        const { animal } = e.target.closest('button').dataset;
        const animalIdInt = parseInt(animal, 10);

        const {
            drawerLevel = 1,
            saveSale = () => {},
            saleIsLocked = false,
        } = this.props;
		const { appState, saleStore, journalStore } = this.props.stores;
        const { newSale } = saleStore;
        const isSaved = await saveSale({ calledFrom: 'listAnimalMini.onClickNewJournal' });
		appState.openDrawerRight('newJournal', {
            isNew: true,
            hideAdmin: true,
            saveSale,
            saleIsLocked,
            calendarEventId: newSale.calendarEvent,
            animals: animals.filter(a => a.id === animalIdInt),
            callback: async (nj, keepOpen) => {
                const { newJournal } = journalStore;
                // console.log('onClickNewJournal callback', newJournal);
                if (!keepOpen) {
                    appState.toggleDrawerRight(false, drawerLevel + 1);
                }
                if (newJournal) {
                    if (!newJournal.id) {
                        await this.saveJournal();
                    }
                    const { newSale } = saleStore;
                    const { journals } = newSale;
                    const isFound = journals.find(a => a.id === newJournal.id);
                    const idx = journals.findIndex(a => a.id === newJournal.id);
                    if (idx > -1) {
                        saleStore.updateObjectKeyArrValue('newSale', 'journals', idx, newJournal);
                        await saveSale({ calledFrom: 'listAnimalMini.onClickNewJournal.callback' });
                    } else {
                        saleStore.updateObjectKeyValue('newSale', 'journals', [...journals, newJournal]);
                        await saveSale({ calledFrom: 'listAnimalMini.onClickNewJournal.callback' });
                    }
                    // setTimeout(() => {
                    //     this.loadAll(this.props, false);
                    // }, 1000);
                }
            },
		}, drawerLevel + 1);
    }

    onClickNewNotification = async e => {
        e.stopPropagation();
        e.preventDefault();
        const {
            drawerLevel = 1,
            saveSale = () => {},
            saleIsLocked = false,
            animals = [],
        } = this.props;
		const { appState, saleStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        const { newSale } = saleStore;

        const { animal } = e.target.closest('button').dataset;
        const animalIdInt = parseInt(animal, 10);

        appState.openDrawer('newCalendarEvent', {
			height: drawerHeightMedium,
            isNew: true,
            hideAdmin: true,
            calendarType: 'notification',
            animals: animals.filter(a => a.id === animalIdInt),
            inputDate: newSale.saleDate,
            saleIsLocked,
            callback: async booking => {
                // console.log('newCalendarEvent callback', booking);
                appState.toggleDrawer(false, drawerLevel + 1);
            },
		}, drawerLevel + 1);
    }

    onClickNewAdmission = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('button').dataset;

        const { animalStore, saleStore } = this.props.stores;

        const { animals } = this.props;;
        const animal = animals.find(a => a.id === parseInt(id, 10));

        // updateObjectFieldByName({ objectName, namePlural = this.namePlural, id, field, value, findBy = '
        if (!animal) {
            return;
        }
        await animalStore.save({
            isAdmittedToClinic: animal.isAdmittedToClinic ? 'false' : 'true',
        }, parseInt(id, 10));

        saleStore.updateObjectFieldByName({
            objectName: 'newSale',
            namePlural: 'animals',
            id: parseInt(id, 10),
            field: 'isAdmittedToClinic',
            value: animal.isAdmittedToClinic ? false : true,
        });
    }

    onClickNewBooking = async e => {
        e.stopPropagation();
        e.preventDefault();
        const {
            drawerLevel = 1,
            saveSale = () => {},
            saleIsLocked = false,
            animals = [],
        } = this.props;
		const { appState, saleStore, calendarStore } = this.props.stores;
        const { drawerHeightLarge } = appState;
        const { newSale } = saleStore;

        const { animal } = e.target.closest('button').dataset;
        const animalIdInt = parseInt(animal, 10);

        appState.openDrawer('newCalendarEvent', {
			height: drawerHeightLarge,
            isNew: true,
            hideAdmin: true,
            calendarType: 'booking',
            animals: animals.filter(a => a.id === animalIdInt),
            inputDate: newSale.saleDate,
            saleIsLocked,
            callback: async booking => {
                // console.log('newCalendarEvent callback', booking);
                appState.toggleDrawer(false, drawerLevel + 1);
                calendarStore.resetDragging();
                this.loadAnimalBookings();
            },
		}, drawerLevel + 1);
    }

    onClickSendBookingSms = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('button').dataset;
        const { calendarEventStore } = this.props.stores;
        // console.log('onClickSendBookingSms', id);
        const { smsSent, smsSending } = this.state;
        smsSending[id] = true;
        this.setState({ smsSending });

        await calendarEventStore.sendSMS(id);

        smsSending[id] = false;
        smsSent[id] = true;
        this.setState({ smsSent, smsSending });
    }

    onClickViewBooking = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.calendar-event').dataset;

        const { appState, calendarStore } = this.props.stores;
        const { eventId } = calendarStore
        if (eventId) {
            return;
        }

        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { drawerHeightMedium } = appState;

        appState.openDrawer('viewCalendarEvent', {
            height: drawerHeightMedium,
            id: parseInt(id, 10),
            callback: event => {
                callback(event);
                appState.toggleDrawer(false, drawerLevel + 2);
                calendarStore.resetDragging();
            },
        }, drawerLevel + 2);
    }

    onClickFindFiles = e => {
        e.stopPropagation();
        e.preventDefault();
        const { appState } = this.props.stores;
        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { drawerHeightMedium } = appState;

        appState.openDrawer('listFileTable', {
            height: drawerHeightMedium,
            callback: event => {},
        }, drawerLevel + 1);
    }

    onClickSendToDyreId = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('button').dataset;
        const { animalStore } = this.props.stores;
        const { sendingToDyreId = {} } = this.state;

        sendingToDyreId[id] = true;
        this.setState({ sendingToDyreId });

        await animalStore.dyreIdRegisterPetOwnerInfoV1(parseInt(id, 10));
        sendingToDyreId[id] = false;
        this.setState({ sendingToDyreId });

        const { sentToDyreId } = this.state;
        sentToDyreId[id] = true;
        this.setState({ sentToDyreId });
    }

    onClickCheckChipId = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { chipid, animal } = e.target.closest('i').dataset;
        const { animalStore } = this.props.stores;
        const { checkingWithDyreId = {} } = this.state;

        checkingWithDyreId[chipid] = true;
        this.setState({ checkingWithDyreId });

        const response = await animalStore.dyreIdGetChipInfo(chipid);
        // console.log({ response: response.data });
        const { dyreIdResponse } = this.state;
        dyreIdResponse[chipid] = response?.data;
        this.setState({ dyreIdResponse });
        checkingWithDyreId[chipid] = false;
        this.setState({ checkingWithDyreId });
    }

    closeDyreIdInfo = e => {
        e.stopPropagation();
        e.preventDefault();
        const { chipid } = e.target.closest('i').dataset;
        const { dyreIdResponse } = this.state;
        delete dyreIdResponse[chipid];
        this.setState({ dyreIdResponse });
    }

    loadAnimalBookings = async (props = this.props) => {
        const { animals = [] } = props;
        const { calendarEventStore } = this.props.stores;
        const animalIds = animals.map(v => v.id);
        const animalBookings = await calendarEventStore.load({ query: { animal: animalIds, future: 1 }, skipUpdate: 1 });
        calendarEventStore.updateKeyValue('animalBookings', animalBookings);
    }

    loadAll = async (props = this.props) => {
        const { calendarEventStore, animalStore } = this.props.stores;
        calendarEventStore.updateKeyValue('animalBookings', []);
        await this.loadAnimalBookings(props);

        // this.setState({ sendingToDyreId: false });

//         const { animals = [] } = props;
//         animalStore.updateKeyValue('currentAnimals', []);
//         const animalsIds = animals.map(v => v.id);
// console.log({ animalsIds, animals });
//         if (animalsIds && animalsIds.length > 0) {
//             const currentAnimals = await animalStore.load({ query: { id: animalsIds }, skipUpdate: 1 });
//             animalStore.updateKeyValue('currentAnimals', currentAnimals);
//         }
    }

    dyreIdGetChipInfo = async (chipId) => {
        const { animalStore } = this.props.stores;
        const { animal } = animalStore;
        const response = await animalStore.dyreIdGetChipInfo(chipId);
        const { data } = response;
        // console.log({ data });
    }

    updateAnimal = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { id, field, value, field2, value2 } = e.target.closest('button').dataset;
        const { updatingAnimal, updatedAnimal } = this.state;
        if (!updatingAnimal[id]) {
            updatingAnimal[id] = {};
        }
        updatingAnimal[id][field] = true;
        this.setState({ updatingAnimal });

        const { animalStore } = this.props.stores;
        await animalStore.save({
            id: parseInt(id, 10),
            [field]: value,
            [field2]: value2,
        });

        updatingAnimal[id][field] = false;
        this.setState({ updatingAnimal });

        if (!updatedAnimal[id]) {
            updatedAnimal[id] = {};
        }
        updatedAnimal[id][field] = true;
        this.setState({ updatedAnimal });
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.animals !== this.props.animals) {
            this.loadAll(nextProps)
        }
    }

    render() {
        const {
            smsSent,
            smsSending,
            checkingWithDyreId = {},
            sendingToDyreId = {},
            sentToDyreId = {},
            dyreIdResponse = {},
            updatingAnimal = {},
            updatedAnimal = {},
        } = this.state;
        const {
            animals: inputAnimals = [],
            journals = [],
            saleIsLocked = false,
            classNames = '',
            showNewJournal = true,
            showNewBooking = true,
            showNewNotification = true,
            showAdmission = true,
            journalsTitle,
            drawerLevel = 1,
            saveSale = () => {},
            showJournals = true,
        } = this.props;

        const { userStore, calendarEventStore, animalStore } = this.props.stores;
        const { user, isAdmin, customer } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { animalBookings } = calendarEventStore;
        const { currentAnimals, animals } = animalStore;

        let finalAnimals = inputAnimals;
        if (currentAnimals && currentAnimals.length > 0) {
            finalAnimals = currentAnimals;
        }
        // console.log({ inputAnimals });
        return (<>
            {(finalAnimals && finalAnimals.length > 0) && <>
                {finalAnimals.map(e => {
                    const animal = animals.find(a => a.id === e.id) || e;
                    const animalJournals = journals.filter(j => j.animal === animal.id);
                    const currentAnimalBookings = animalBookings.filter(b => {
                        const bookingAnimals = b.animals ? b.animals.map(a => a.id) : [];
                        const isFound = bookingAnimals.includes(animal.id);
                        return isFound;
                    });

                    const dyreIdInfo = dyreIdResponse[animal.chipId];
                    // "BirthDate": "29",
                    // "BirthMonth": "10",
                    // "BirthYear": "2021",
                    const dyreIdBirth = `${dyreIdInfo?.BirthYear}-${util.padDate(dyreIdInfo?.BirthMonth)}-${util.padDate(dyreIdInfo?.BirthDate)}`;
                    const animalBirth = animal.birth ? util.isoDate(animal.birth, false, false, true) : '';

                    return (<>
                        <div class={`d-flex flex-column border box-container mt-2 bg-light p-2 ${classNames}`}>
                            {e && <>
                                <div class={`d-flex flex-row justify-content-between px-3 py-0 animal-line ${e.isDececed ? 'bg-warning rounded-lg' : ''}`} onClick={this.onClickViewAnimal} data-id={animal.id}>
                                    {/* {!saleIsLocked && <> */}
                                        <div class='mr-2 animal-line' onClick={this.onClickEditAnimal} data-id={animal.id}>
                                            <i class='fa-solid fa-edit' />
                                        </div>
                                    {/* </>} */}
                                    <div class='flex-fill'>
                                        {e.isDececed ? <i class='fa-duotone fa-tombstone mr-2' /> : ''}
                                        {animal.name} ({animal.species}/{animal.breed})
                                        {e.chipId && <><i
                                            class={`fa-solid fa-microchip text-muted ${checkingWithDyreId[e.chipId] ? 'fa-spin' : ''} ml-2`}
                                            onClick={this.onClickCheckChipId}
                                            data-chipid={e.chipId}
                                            data-animal={animal.id}
                                        /> {e.chipIdChecked && <small class='text-muted font-weight-lighter'>{util.formatDate(e.chipIdChecked)}</small>}</>}
                                        {e.dyreIdPetId && <><span class='ml-2 font-weight-lighter text-muted'><small>DyreId</small></span></>}
                                    </div>
                                    {showAdmission && <div class='ml-2'>
                                        {animal.isAdmittedToClinic ? <>
                                            <button class='btn btn-sm btn-outline-success rounded-pill ml-2 py-0' onClick={this.onClickNewAdmission} data-id={animal.id}>
                                                <i class='fa-solid fa-bed' /> <Text id='dyrejournal.leave-clinic'>Leave clinic</Text>
                                            </button>
                                        </> : <>
                                            <button class='btn btn-sm btn-outline-danger rounded-pill ml-2 py-0' onClick={this.onClickNewAdmission} data-id={animal.id}>
                                                <i class='fa-solid fa-bed' /> <Text id='dyrejournal.admission.new'>New admission</Text>
                                            </button>
                                        </>}
                                    </div>}
                                    <div class='ml-2'>
                                        <nobr>{animal.birth && util.age(animal.birth)}</nobr>
                                    </div>
                                    {(!saleIsLocked && animalJournals.length === 0) && <>
                                        <div class='ml-2' onClick={this.onClickRemoveAnimal} data-id={animal.id}>
                                            <i class='fa-solid fa-xmark' />
                                        </div>
                                    </>}
                                </div>
                                {dyreIdInfo && <div class='d-flex flex-column justify-content-between px-3 py-0  mt-2 animal-line'>
                                    <div class='d-flex flex-row justify-content-end'>
                                        <i class='fa-solid fa-times text-danger' onClick={this.closeDyreIdInfo} data-chipid={e.chipId} />
                                    </div>
                                    {dyreIdInfo.IsSuccess === 'true' ? <>
                                        <div class='alert alert-success rounded-pill mb-0'>
                                            <Text id='dyrejournal.dyreid-success'>Animal found in DyreID</Text>
                                        </div>
                                        <div class='d-flex flex-row justify-content-between'>
                                            <div class='font-weight-lighter text-muted'>
                                                <small>
                                                    <Text id='dyrejournal.dyreid-info-about'>Info from DyreID compared to your info.</Text>
                                                </small>
                                            </div>
                                        </div>
                                        <table class='table table-sm table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th><Text id='dyrejournal.dyrejournal-info'>Your info</Text></th>
                                                    <th>&nbsp;</th>
                                                    <th><Text id='dyrejournal.dyreid-info'>DyreID</Text></th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* <tr>
                                                    <td>{animal.owner}</td>
                                                    <td class='font-italic'>{dyreIdInfo.OwnerFullName}</td>
                                                    <td>&nbsp;</td>
                                                </tr> */}
                                                <tr>
                                                    <td class='font-weight-lighter'><Text id='input.name'>Name</Text></td>
                                                    <td>{animal.name}</td>
                                                    <td class='text-center'>
                                                        <i class='fa-solid fa-arrow-right' />
                                                    </td>
                                                    <td class='font-italic'>{dyreIdInfo.PetName}</td>
                                                    <td class='text-center'>
                                                        {updatingAnimal[animal.id] && updatingAnimal[animal.id].name ? <>
                                                            <i class='fa-solid fa-spin fa-spinner' />
                                                        </> : <>
                                                            {updatedAnimal[animal.id] && updatedAnimal[animal.id].name ? <>
                                                                <i class='fa-solid fa-check text-success' />
                                                            </> : <>
                                                                {animal.name !== dyreIdInfo.PetName ? <>
                                                                    <button
                                                                        class='btn btn-link'
                                                                        onClick={this.updateAnimal}
                                                                        data-id={animal.id}
                                                                        data-field='name'
                                                                        data-value={dyreIdInfo.PetName}
                                                                    >
                                                                        <Text id='dyrejournal.dyreid-update'>Update</Text>
                                                                    </button>
                                                                </> : <>
                                                                    <i class='fa-solid fa-check text-success' />
                                                                </>}
                                                            </>}
                                                        </>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class='font-weight-lighter'><Text id='input.birth'>Birth</Text></td>
                                                    <td>{animalBirth}</td>
                                                    <td class='text-center'>
                                                        <i class='fa-solid fa-arrow-right' />
                                                    </td>
                                                    <td class='font-italic'>{dyreIdBirth}</td>
                                                    <td class='text-center'>
                                                        {updatingAnimal[animal.id] && updatingAnimal[animal.id].birth ? <>
                                                            <i class='fa-solid fa-spin fa-spinner' />
                                                        </> : <>
                                                            {updatedAnimal[animal.id] && updatedAnimal[animal.id].birth ? <>
                                                                <i class='fa-solid fa-check text-success' />
                                                            </> : <>
                                                                {animalBirth !== dyreIdBirth ? <>
                                                                    <button
                                                                        class='btn btn-link'
                                                                        onClick={this.updateAnimal}
                                                                        data-id={animal.id}
                                                                        data-field='birth'
                                                                        data-value={dyreIdBirth}
                                                                    >
                                                                        <Text id='dyrejournal.dyreid-update'>Update</Text>
                                                                    </button>
                                                                </> : <>
                                                                    <i class='fa-solid fa-check text-success' />
                                                                </>}
                                                            </>}
                                                        </>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class='font-weight-lighter'><Text id='input.species'>Species</Text></td>
                                                    <td>{animal.species}</td>
                                                    <td class='text-center'>
                                                        <i class='fa-solid fa-arrow-right' />
                                                    </td>
                                                    <td class='font-italic'>{dyreIdInfo.Species}</td>
                                                    <td class='text-center'>
                                                        {updatingAnimal[animal.id] && updatingAnimal[animal.id].species ? <>
                                                            <i class='fa-solid fa-spin fa-spinner' />
                                                        </> : <>
                                                            {updatedAnimal[animal.id] && updatedAnimal[animal.id].species ? <>
                                                                <i class='fa-solid fa-check text-success' />
                                                            </> : <>
                                                                {animal.species !== dyreIdInfo.Species || animal.speciesId !== parseInt(dyreIdInfo.SpeciesCode, 10) ? <>
                                                                    <button
                                                                        class='btn btn-link'
                                                                        onClick={this.updateAnimal}
                                                                        data-id={animal.id}
                                                                        data-field='species'
                                                                        data-value={dyreIdInfo.Species}
                                                                        data-field2='speciesId'
                                                                        data-value2={dyreIdInfo.SpeciesCode}
                                                                    >
                                                                        <Text id='dyrejournal.dyreid-update-to'>Update to</Text> "{dyreIdInfo.Species}"
                                                                    </button>
                                                                </> : <>
                                                                    <i class='fa-solid fa-check text-success' />
                                                                </>}
                                                            </>}
                                                        </>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class='font-weight-lighter'><Text id='input.breed'>Breed</Text></td>
                                                    <td>{animal.breed}</td>
                                                    <td class='text-center'>
                                                        <i class='fa-solid fa-arrow-right' />
                                                    </td>
                                                    <td class='font-italic'>{dyreIdInfo.Breed}</td>
                                                    <td class='text-center'>
                                                        {updatingAnimal[animal.id] && updatingAnimal[animal.id].breed ? <>
                                                            <i class='fa-solid fa-spin fa-spinner' />
                                                        </> : <>
                                                            {updatedAnimal[animal.id] && updatedAnimal[animal.id].breed ? <>
                                                                <i class='fa-solid fa-check text-success' />
                                                            </> : <>
                                                                {animal.breed !== dyreIdInfo.Breed || animal.breedId !== parseInt(dyreIdInfo.BreedCode, 10) ? <>
                                                                    <button
                                                                        class='btn btn-link'
                                                                        onClick={this.updateAnimal}
                                                                        data-id={animal.id}
                                                                        data-field='breed'
                                                                        data-value={dyreIdInfo.Breed}
                                                                        data-field2='breedId'
                                                                        data-value2={dyreIdInfo.BreedCode}
                                                                    >
                                                                        <Text id='dyrejournal.dyreid-update-to'>Update to</Text> "{dyreIdInfo.Breed}"
                                                                    </button>
                                                                </> : <>
                                                                    <i class='fa-solid fa-check text-success' />
                                                                </>}
                                                            </>}
                                                        </>}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {/* {animal.breed} === {dyreIdInfo.Breed}<br />
                                        {animal.breedId} === {dyreIdInfo.BreedCode}<br />
                                        {animal.Species} === {dyreIdInfo.Species}<br />
                                        {animal.speciesId} === {dyreIdInfo.SpeciesCode}<br /> */}
                                    </> : <>
                                        <div class='alert alert-danger rounded-pill'>
                                            <Text id='dyrejournal.dyreid-error'>Animal not found in DyreID</Text>
                                        </div>
                                    </>}

                                    {/* <xmp>{JSON.stringify(dyreIdInfo, null, 2)}</xmp> */}

                                    {/* {
                                        "IsSuccess": "true",
                                        "AddressLine1": "Nord-Torpveien 1561",
                                        "BirthDate": "29",
                                        "BirthMonth": "10",
                                        "BirthYear": "2021",
                                        "Breed": "Ukjent",
                                        "BreedCode": "609",
                                        "ChipId": "578098100762746",
                                        "City": "Skedsmokorset",
                                        "Contacts": {
                                            "ContactInfo": {
                                            "ContactNumber": "90169741",
                                            "ContactType": "2"
                                            }
                                        },
                                        "Country": "+47",
                                        "IsDead": "false",
                                        "IsLost": "false",
                                        "OwnerFirstName": "Marius",
                                        "OwnerFullName": "Marius Hagen",
                                        "OwnerLastName": "Hagen",
                                        "Pedigree": "Solbergmutt's Iditarod Ariel",
                                        "PetName": "Solbergmutt's Iditarod Ariel",
                                        "PostalCode": "2880",
                                        "PrimaryTelephoneNo": "+47 90169741",
                                        "RegDato": null,
                                        "Species": "Hund",
                                        "SpeciesCode": "1",
                                        "State": "Akershus"
                                    } */}


                                </div>}
                                {(animal.cave || animal.isAggressive == 1) ? <>
                                    <div class={`d-flex flex-row justify-content-between px-3 py-0  mt-2 animal-line`} onClick={this.onClickEditAnimal} data-id={animal.id}>
                                        <div class='flex-fill'>
                                            <div class='p-2 mb-2 bg-danger text-white rounded-lg d-flex flex-column'>
                                                {animal.cave ? <>
                                                    <div>
                                                        CAVE: {animal.cave}
                                                    </div>
                                                </> : <>
                                                </>}
                                                {animal.isAggressive ? <>
                                                    <div>
                                                        <i class='fa-solid fa-exclamation-triangle mr-2' /> <Text id='dyrejournal.aggressive'>Aggressive behavior</Text>
                                                    </div>
                                                </> : <>
                                                </>}

                                            </div>
                                        </div>
                                    </div>
                                </> : <></>}
                            </>}
                            {currentAnimalBookings && currentAnimalBookings.length > 0 && <>
                                <div>
                                    <small>
                                        <Text id='dyrejournal.booking.title'>Bookings</Text>
                                    </small>
                                </div>
                                <div class='d-flex flex-column px-3 py-0 text-muted'>
                                    {currentAnimalBookings.map(b => {
                                        const isNotification = b.calendarId	=== 'Innkallinger';
                                        return (<>
                                            <div>
                                                <small>
                                                    {isNotification ? <>
                                                        <i class='fa-duotone fa-bell mr-2' />
                                                        <span class='calendar-event' onClick={this.onClickViewBooking} data-id={b.id}>{util.formatDate(b.start, {
                                                            weekday: 'short',
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            locale: 'nb-NO',
                                                        })}</span>
                                                    </> : <>
                                                        <i class='fa-duotone fa-calendar mr-2' />
                                                        <span class='calendar-event' onClick={this.onClickViewBooking} data-id={b.id}>{util.formatDate(b.start, {
                                                            weekday: 'short',
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric',
                                                            locale: 'nb-NO',
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            hour12: false,
                                                        })}</span>-<span>{util.isoTime(b.end)}</span>
                                                    </>}
                                                    <span class='ml-2 calendar-event' onClick={this.onClickViewBooking} data-id={b.id}>{b.title}</span>
                                                    {!isNotification && <>
                                                        <button
                                                            type='button'
                                                            class={`btn btn-sm btn-${smsSent[b.id] || b.reminderSMSSent ? 'success' : 'outline-primary'} rounded-pill ml-2 py-0`}
                                                            onClick={this.onClickSendBookingSms}
                                                            data-id={b.id}
                                                            disabled={smsSent[b.id]}
                                                        >
                                                            {b.reminderSMSSent ? <>
                                                                <i class='fa-solid fa-sms' /> <Text id='dyrejournal.booking.sms-sent'>SMS sent</Text>
                                                            </> : <>
                                                                <i class='fa-solid fa-sms' /> <Text id='dyrejournal.booking.send-sms'>Send SMS</Text>
                                                            </>}
                                                            {smsSending[b.id] && <i class='fa-solid fa-spinner-third fa-spin ml-2' />}
                                                            {smsSent[b.id] && <i class='fa-solid fa-check ml-2' />}
                                                        </button>
                                                    </>}
                                                </small>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </>}

                            {showJournals && <DyrejournalJournalListMini
                                classNames='mx-1'
                                stores={this.props.stores}
                                journals={animalJournals.reverse()}
                                drawerLevel={drawerLevel}
                                // saleIsLocked={saleIsLocked}
                                animals={[e]}
                                collapse={true}
                                saveSale={saveSale}
                            />}

                            {(showNewJournal || showNewNotification || showNewBooking) && <div class={`d-flex flex-row justify-content-center mt-3 px-3 ${classNames}`}>
                                {showNewJournal && <button class='btn btn-sm btn-outline-primary rounded-pill ml-2 py-0' onClick={this.onClickNewJournal} data-animal={animal.id}>
                                    <i class='fa-solid fa-pen-nib' /> <Text id='dyrejournal.journal.new'>New journal</Text>
                                </button>}
                                {showNewBooking && <button class='btn btn-sm btn-outline-success rounded-pill ml-2 py-0' onClick={this.onClickNewBooking} data-animal={animal.id}>
                                    <i class='fa-duotone fa-calendar' /> <Text id='dyrejournal.booking.new'>New booking</Text>
                                </button>}
                                {showNewNotification && <button class='btn btn-sm btn-outline-warning rounded-pill ml-2 py-0' onClick={this.onClickNewNotification} data-animal={animal.id}>
                                    <i class='fa-solid fa-calendar' /> <Text id='dyrejournal.notification.new'>New notification</Text>
                                </button>}
                                <button class='btn btn-sm btn-outline-info rounded-pill ml-2 py-0' onClick={this.onClickFindFiles}>
                                    <i class='fa-solid fa-files' /> <Text id='dyrejournal.find-files'>Find files</Text>
                                </button>

                                {/* {isAdmin && (customer.dyreIdClinicId && e.chipId && !sentToDyreId[e.id] && !e.dyreIdPetId) && <>
                                    <button
                                        disabled={sendingToDyreId[e.id]}
                                        class='btn btn-sm btn-info rounded-pill ml-2'
                                        data-id={e.id}
                                        onClick={this.onClickSendToDyreId}
                                    >
                                        {sendingToDyreId[e.id] && <><i class='fa-solid fa-spinner fa-spin mr-2' /></>}
                                        Admin: <i class='fa-duotone fa-share-from-square' /> <Text id='dyrejournal.sendToDyreId'>Send to DyreId</Text>
                                    </button>
                                </>} */}
                                {/* {isAdmin && e.chipId && <>
                                    <button
                                        disabled={sendingToDyreId[e.chipId]}
                                        class='btn btn-sm btn-info rounded-pill ml-2'
                                        data-chipid={e.chipId}
                                        onClick={this.onClickCheckChipId}
                                    >
                                        {sendingToDyreId[e.chipId] && <><i class='fa-solid fa-spinner fa-spin mr-2' /></>}
                                        Admin: <i class='fa-duotone fa-share-from-square' /> <Text id='dyrejournal.dyreIdCheckChipId'>Check chip with DyreId</Text>
                                    </button>
                                </>}
                                {sentToDyreId[e.id] && <>
                                    <small class='ml-2 py-1'>
                                        <i class='fa-solid fa-check' /> <Text id='dyrejournal.sentToDyreId'>Sent to DyreId</Text>
                                    </small>
                                </>} */}
                            </div>}
                        </div>
                    </>);
                })}
            </>}

            {!(finalAnimals && finalAnimals.length > 0) && <>
                <div class='d-flex flex-column justify-content-center w-100 my-1 text-muted'>
                    <div class='w-100 text-center' style='font-size: 1.5em;'>
                        <i class='fa-solid fa-dog' />
                    </div>
                    <div class='w-100 text-center' style='font-size: 1.0em;'>
                        <Text id='dyrejournal.animal.no-animal-selected'>No animals selected</Text>
                    </div>
                </div>
            </>}
        </>);
    }
}

export default AnimalListMini;
