import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

@withText(props => ({
    drugTitle: <Text id='dyrejournal.drug.search-title'>Search for drug</Text>,
    animaliaSearchTitle: <Text id='dyrejournal.journal-animalia-search-title'>Search for individuals in DHP</Text>,
}))
@observer
class AddAnimal extends Component {
	constructor(props) {
        super(props);
        this.state = {
            // sections: fields.get('journal'),
        };
        this.searchTimer = null;
    }

    // DHP
    animaliaSearchInput = (e) => {
        const animaliaSearch = e.target.value;
        this.setState({ animaliaSearch });

        clearTimeout(this.searchTimer);
        if (animaliaSearch.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.animaliaDoSearch(animaliaSearch);
            }, 300);
        }
    }

    async animaliaDoSearch() {
        const { producerNumber } = this.props;
        const { appState } = this.props.stores;
        if (producerNumber) {
            const response = await appState.animaliaIndividualList(producerNumber);
            const animalList = response?.data?.storfeliste;
            this.setState({ animalList });
        }
        // journalStore.updateKeyValue('allJournals', response);
    }

    animaliaClearSearch = () => {
        this.setState({ animaliaSearch: '' });
        this.animaliaDoSearch('');
    }

    animaliaHandleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.animaliaDoSearch(this.state.animaliaSearch);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.animaliaClearSearch();
        }
    }

    onClickGotoAnimaliaAuth = e => {
        e.preventDefault();

        const { userStore } = this.props.stores;
        const { user } = userStore;

        // Open popup window to Animalia Auth
        const apiServer = util.getApiServer();

        const animalia_redirect_uri = `${apiServer}/api/integrations/animalia/token-exchange`;
        const animalia_client_id = 'dyrejournal';
        const animalia_state = user.id;
        const animalia_scope = encodeURIComponent('openid profile offline_access');

        const animalia_auth_url = `https://staging-sso.animalia.no/authorize?redirect_uri=${animalia_redirect_uri}&client_id=${animalia_client_id}&state=${animalia_state}&auto_login=true&response_type=code&scope=${animalia_scope}`;
        window.open(animalia_auth_url, 'Animalia Auth', 'width=600,height=600');
    }
    // /DHP

    loadAll = async (props = this.props) => {
        this.animaliaDoSearch('');
    }

    onClickChooseAnimal = async e => {
        const { addingAnimal } = this.state;
        if (addingAnimal) {
            return;
        }
        this.setState({ addingAnimal: true });
        const { tag } = e.target.closest('tr').dataset;
        const { animalList = [] } = this.state;
        const { drawerLevel = 1, saveSale = () => {}, loadAnimals = () => {}, loadJournals = () => {} } = this.props;
        const [opprinnelsesmerke, oremerke] = tag.split('-');
        const animal = animalList.find(a => a.opprinnelsesmerke === opprinnelsesmerke && a.oremerke === oremerke);

        const { appState, saleStore, animalStore, journalStore } = this.props.stores;
        const { newSale, visitorAnimals = [] } = saleStore;
        const { animals = [], visitors = [] } = newSale;
        const visitor = visitors[0];
        const visitorIds = visitors.map(v => v?.id);

        // console.log('opprinnelsesmerke', opprinnelsesmerke, 'oremerke', oremerke, 'animal', animal, 'animals', animals);
        // Check if animal is already in list for this sale
        if (animals.find(a => a.opprinnelsesmerke === opprinnelsesmerke && a.oremerke === oremerke)) {
            this.setState({ addingAnimal: false });
            return;
        }
        if (animals.find(a => a.markOfOrigin === opprinnelsesmerke && a.earMark === oremerke)) {
            this.setState({ addingAnimal: false });
            return;
        }

        // Check if animal is already in list for this visitor
        let currentAnimal = visitorAnimals.find(a => a.markOfOrigin === opprinnelsesmerke && a.earMark === oremerke);
        if (!currentAnimal) {
            const response = await animalStore.insert({
                ...animal,
                name: `${animal.opprinnelsesmerke}-${animal.oremerke}`,
                markOfOrigin: animal.opprinnelsesmerke,
                earMark: animal.oremerke,
                birth: animal.fodselsdato,
                // gender: animal['kjonn-id'],
                speciesId: 13,
                owner: visitor.id,
            });
            currentAnimal = response?.data?.animal;
            await loadAnimals(visitorIds);
        }

        const allAnimals = [...animals, {
            ...animal,
            name: `${animal.opprinnelsesmerke}-${animal.oremerke}`,
            birth: animal.fodselsdato,
            id: currentAnimal.id,
            speciesId: 13,
        }]
        saleStore.updateObjectKeyValue('newSale', 'animals', allAnimals);

        // Create new journal for this animal and insert this into the sale.
        const newJournal = {
            animal: currentAnimal.id,
            visitor: visitor.id,
            veterinary: newSale.veterinary,
        };
        const response = await journalStore.insert(newJournal);
        const journal = response?.data?.journal;

        // Insert this journal into the sale.
        saleStore.updateObjectKeyValue('newSale', 'journals', [...newSale.journals, journal]);

        await saveSale({ calledFrom: 'addAnimal.onClickChooseAnimal' });

        const animalsIds = allAnimals.map(v => v?.id);
        const journalsIds = newSale.journals.map(v => v?.id);
        // console.log('animalsIds', animalsIds);
        await loadJournals(animalsIds, journalsIds);
        this.setState({ addingAnimal: false });

        // appState.toggleDrawer(false, drawerLevel);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            animaliaSearch,
            animalList = [],
            addingAnimal,
        } = this.state;
        const {
            animaliaSearchTitle,
            drawerLevel,
        } = this.props;
        const { userStore, saleStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { visitorAnimals = [], newSale } = saleStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { animals = [], visitors = [] } = newSale;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.add-animal'>Add animal</Text>
                            </div>

                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                                <div class='d-flex flex-column'>
                                    <div class='d-flex flex-row justify-content-between'>
                                        <div class='input-group'>
                                            <div class='input-group-prepend'>
                                                <span class='input-group-text rounded-pill-left'>
                                                    <i class='fa-regular fa-magnifying-glass'></i>
                                                </span>
                                            </div>
                                            <input
                                                class={`form-control ${!animaliaSearch ? 'rounded-pill-right' : ''}`}
                                                type='text'
                                                value={animaliaSearch}
                                                placeholder={`${animaliaSearchTitle}`}
                                                onInput={this.animaliaSearchInput}
                                                onKeyDown={this.animaliaHandleKeyDown}
                                            />
                                            {animaliaSearch && <div class='input-group-append'>
                                                <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.animaliaClearSearch}>
                                                    <i class='fa-solid fa-circle-xmark'></i>
                                                </button>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {addingAnimal && <div class='d-flex flex-row justify-content-center'>
                                <div class='spinner-border text-primary' role='status'>
                                    <span class='sr-only'>Loading...</span>
                                </div>
                            </div>}

                            <div class={`d-flex flex-column mx-3 mt-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <table class='table table-striped mb-0'>
                                        <thead>
                                            <tr>
                                                <th><Text id='dyrejournal.animalia-origin-mark'>Origin mark</Text></th>
                                                <th><Text id='dyrejournal.animalia-ear-mark'>Ear mark</Text></th>
                                                <th class='text-right'><Text id='dyrejournal.animalia-birthdate'>Birthdate</Text></th>
                                                <th><Text id='dyrejournal.animalia-gender'>Gender</Text></th>
                                                <th class='text-right'><Text id='dyrejournal.animalia-race'>Race id</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {animalList && animalList.map(animal => {
                                                // {
                                                //     "produsent-nr": "3448006381",
                                                //     "opprinnelsesmerke": "2499816",
                                                //     "kjonn-id": "2",
                                                //     "oremerke": "00318",
                                                //     "fodselsdato": "2020-10-19",
                                                //     "rase-kode": "01"
                                                // },
                                                const currentAnimal = visitorAnimals.find(a => a.markOfOrigin === animal.opprinnelsesmerke && a.earMark === animal.oremerke);

                                                return (<>
                                                    <tr
                                                        onClick={this.onClickChooseAnimal}
                                                        data-tag={`${animal.opprinnelsesmerke}-${animal.oremerke}`}
                                                        class={`${animals.find(a => a.id === currentAnimal?.id) ? 'bg-success-light' : ''}`}
                                                    >
                                                        <td>
                                                            <i class={`fa-duotone ${currentAnimal?.id ? 'fa-check' : 'fa-times'}`} />
                                                        </td>
                                                        <td>
                                                            {animal.opprinnelsesmerke}
                                                        </td>
                                                        <td>
                                                            {animal.oremerke}
                                                        </td>
                                                        <td class='text-right'>
                                                            {animal.fodselsdato}
                                                        </td>
                                                        <td>
                                                            {animal['kjonn-id']}
                                                        </td>
                                                        <td class='text-right'>
                                                            {animal['rase-kode']}
                                                        </td>
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>



{/* <xmp>{JSON.stringify(animalList, null, 4)}</xmp> */}
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default AddAnimal;
