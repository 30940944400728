import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

function isValidDate(dateString) {
    // console.log('isValidDate', dateString);

    const date = new Date(dateString);
    // Check if the date is invalid
    if (isNaN(date)) {
        // console.log('isNaN(date)', isNaN(date));
        return false;
    }
    // Check if the year is before 1800
    if (date.getFullYear() < 1900) {
        // console.log('date.getFullYear() < 1900', date.getFullYear() < 1900);
        return false;
    }
    // If we reach here, the date is valid
    return true;
}

@withText(props => ({
    drugTitle: <Text id='dyrejournal.drug.search-title'>Search for drug</Text>,
}))
@observer
class AddMedicine extends Component {
	constructor(props) {
        super(props);
        this.state = {
            inputMedicine: {},
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { drugStore } = this.props.stores;
        await drugStore.load();
    }

    onClickChooseDrug = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1, id: journalId } = this.props;
		const { appState, saleStore, drugStore } = this.props.stores;
        const { newSale } = saleStore;
        const { drugs = [] } = newSale;
        const { drugs: allDrugs } = drugStore;
        const selectedItem = allDrugs.find(d => d.id === parseInt(id, 10));
        if (selectedItem) {
            const { journals = [] } = newSale;
            // Add drug to journal with id journalId
            const journal = journals.find(j => j.id === parseInt(journalId, 10));
            appState.toggleDrawer(false, drawerLevel);
        }
    }

    searchDrugCallback = selectedItem => {
        const inputMedicine = {
            id: selectedItem.id,
            name: selectedItem.varenavn,
            fullname: selectedItem.navnFormStyrke,
            uuidv4: selectedItem.uuidv4,
            amountPerAnimal: '',
            unit: selectedItem.enhetDoseringDn,
            no: `${selectedItem.pakningsinfoPakningsstr} ${selectedItem.pakningsinfoEnhetPakningV}`,
            lastThreatment: new Date().toISOString(),
            usageReason: '',
            threatmentBy: '',
            retentionPeriods: '',
            productNumber: selectedItem.varenr,
        };
        this.setState({ selectedItem, inputMedicine });
    }

    onClickAddDrugToJournal = async () => {
        const { inputMedicine } = this.state;
        const { saleStore, journalStore } = this.props.stores;
        const { id: journalId, saleLoadJournals = () => {} } = this.props;
        const { newSale } = saleStore;
        const { journals = [] } = newSale;
        const journal = journals.find(j => j.id === parseInt(journalId, 10));

        const value = {
            ...inputMedicine,
            md5: md5(JSON.stringify({ ...inputMedicine, date: new Date() })).toString(),
            date: new Date(),
        };

        await journalStore.saveField(journal.id, 'medicine', value);
        await saleLoadJournals();
        this.setState({ selectedItem: null, inputMedicine: {} });
    }

    onClickDeleteDrugFromJournal = async e => {
        const { md5 } = e.target.closest('button').dataset;
        const { saleStore, journalStore } = this.props.stores;
        const { id: journalId, saleLoadJournals = () => {} } = this.props;
        const { newSale } = saleStore;
        const { journals = [] } = newSale;
        const journal = journals.find(j => j.id === parseInt(journalId, 10));
        await journalStore.removeArrayObject({ field: 'medicine', id: journalId, md5 });
        await saleLoadJournals();
    }

    onClickEditLine = e => {
        const { md5 } = e.target.closest('tr').dataset;
        const { journalStore, saleStore } = this.props.stores;
        const { id: journalId } = this.props;

        const { animalJournals } = saleStore;
        const journal = animalJournals.find(j => j.id === parseInt(journalId, 10)) || {};

        const { medicine = [] } = journal;
        const foundMedicine = medicine.find(m => m.md5 === md5);
        const selectedItem = { ...foundMedicine}
        this.setState({ selectedItem, inputMedicine: selectedItem });

        journalStore.updateKeyValue('journal', journal);
    }

    onInput = e => {
        const { inputMedicine } = this.state;
        const { id: journalId } = this.props;
        const { journalStore, saleStore } = this.props.stores;
        const { name, value, type } = e.target;
        const { md5 } = e.target.closest('input').dataset;


        const { journal } = journalStore;
        const idx = journal.medicine.findIndex(m => m.md5 === md5);

        inputMedicine[name] = value;
        this.setState({ inputMedicine });

        if (type === 'datetime-local' && !isValidDate(value)) {
            return false;
        }
        if (type === 'date' && !isValidDate(value)) {
            return false;
        }

        // updateObjectKeyArrFieldValue(obj, key, idx, field, value) {
        journalStore.updateObjectKeyArrFieldValue('journal', 'medicine', idx, name, value);

        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => {
            this.doUpdate(e);
        }, 1000);
    }

    doUpdate = async (e) => {
        const { id: journalId } = this.props;
        const { journalStore } = this.props.stores;
        const { name, value } = e.target;
        const { md5 } = e.target.closest('input').dataset;
        await journalStore.editArrayObject({
            field: 'medicine',
            key: name,
            value,
            id: journalId,
            md5,
        });
    }

    onClickEditDone = async () => {
        const { journalStore } = this.props.stores;
        this.setState({ selectedItem: null, inputMedicine: {} });
        journalStore.updateKeyValue('journal', {});
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { selectedItem, inputMedicine } = this.state;
        const {
            drugTitle,
            id: journalId,
        } = this.props;
        const { userStore, drugStore, saleStore, journalStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { newSale, animalJournals } = saleStore;
        const { journals = [] } = newSale;
        const journal = animalJournals.find(j => j.id === parseInt(journalId, 10)) || {};

        const { medicine = [] } = journal;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.add-medicine'>Add medicine</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <DyrejournalSaleSearch
                                    stores={this.props.stores}
                                    key={'drugs'}
                                    title={drugTitle}
                                    holdingStore={drugStore}
                                    searchResultList={'searchResults'}
                                    searchResultsLine={(e, idx, selectedIndex) => {
                                        return(<>
                                            <div class={`d-flex flex-row justify-content-between px-3 py-1 ${idx > 0 ? 'border-top' : ''} ${idx === selectedIndex ? 'bg-secondary' : ''}`}>
                                                <div>
                                                    {e.navnFormStyrke} ({e.pakningsinfoMengde} {e.pakningsinfoEnhetPakningV})
                                                </div>
                                            </div>
                                        </>);
                                    }}
                                    callback={this.searchDrugCallback}
                                />
                                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <table class='table table-striped table-sm mb-0'>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th><Text id='dyrejournal.medicine'>Medicine</Text></th>
                                                <th><Text id='dyrejournal.medicine-amount-per-animal'>Amount (each animal)</Text></th>
                                                <th><Text id='dyrejournal.medicine-unit'>Unit</Text></th>
                                                <th><Text id='dyrejournal.medicine-no'>No.</Text></th>
                                                <th><Text id='dyrejournal.medicine-last-threatment'>Last threatment</Text></th>
                                                <th><Text id='dyrejournal.medicine-usage-reason'>Usage reason</Text></th>
                                                <th><Text id='dyrejournal.medicine-threatment-by'>Threatment by</Text></th>
                                                <th><Text id='dyrejournal.medicine-retention-periods'>Retention periods</Text></th>
                                                <th><Text id='dyrejournal.medicine-product-number'>Prod num</Text></th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {medicine && medicine.length > 0 && medicine.map((e, idx) => {
                                                return(<>
                                                    <tr data-md5={e.md5}>
                                                        <td onClick={this.onClickEditLine}>{e.md5 === inputMedicine.md5 ? <i class='fa-duotone fa-circle fa-bounce' /> : <i class='fa-duotone fa-pen' />}</td>
                                                        <td>{e.fullname || e.name}</td>
                                                        <td>{e.amountPerAnimal}</td>
                                                        <td>{e.unit}</td>
                                                        <td>{e.no}</td>
                                                        <td>{e.lastThreatment}</td>
                                                        <td>{e.usageReason}</td>
                                                        <td>{e.threatmentBy}</td>
                                                        <td>{e.retentionPeriods}</td>
                                                        <td>{e.productNumber}</td>
                                                        <td>
                                                            <button
                                                                class='btn btn-danger btn-sm py-1 rounded-pill'
                                                                onClick={this.onClickDeleteDrugFromJournal}
                                                                data-md5={e.md5}
                                                            >
                                                                <nobr>
                                                                    <i class='fa-solid fa-trash mr-1' />
                                                                    <Text id='input.delete'>Delete</Text>
                                                                </nobr>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>);
                                            })}

                                            {selectedItem && selectedItem.id && <>
                                                {inputMedicine.md5 ? <>
                                                    <tr class='bg-secondary'>
                                                        <th colspan='11'><Text id='dyrejournal.medicine-edit'>Editing medicine</Text></th>
                                                    </tr>
                                                    <tr class='bg-secondary'>
                                                        <th>&nbsp;</th>
                                                        <td><small>{inputMedicine.name}</small></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='amountPerAnimal' value={inputMedicine.amountPerAnimal} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='unit' value={inputMedicine.unit} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='no' value={inputMedicine.no} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='datetime-local' class='form-control form-control-sm' name='lastThreatment' value={inputMedicine.lastThreatment} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='usageReason' value={inputMedicine.usageReason} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='threatmentBy' value={inputMedicine.threatmentBy} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='retentionPeriods' value={inputMedicine.retentionPeriods} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='productNumber' value={inputMedicine.productNumber} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td>
                                                            <button class='btn btn-primary btn-sm py-1 rounded-pill' onClick={this.onClickEditDone}>
                                                                <nobr>
                                                                    <i class='fa-solid fa-floppy-disk mr-1' />
                                                                    <Text id='input.done'>Done</Text>
                                                                </nobr>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </> : <>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <td><small>{selectedItem.navnFormStyrke}</small></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='amountPerAnimal' value={inputMedicine.amountPerAnimal} onInput={linkstate(this, 'inputMedicine.amountPerAnimal')} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='unit' value={inputMedicine.unit} onInput={linkstate(this, 'inputMedicine.unit')} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='no' value={inputMedicine.no} onInput={linkstate(this, 'inputMedicine.no')} /></td>
                                                        <td><input type='datetime-local' class='form-control form-control-sm' name='lastThreatment' value={inputMedicine.lastThreatment} onInput={linkstate(this, 'inputMedicine.lastThreatment')} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='usageReason' value={inputMedicine.usageReason} onInput={linkstate(this, 'inputMedicine.usageReason')} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='threatmentBy' value={inputMedicine.threatmentBy} onInput={linkstate(this, 'inputMedicine.threatmentBy')} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='retentionPeriods' value={inputMedicine.retentionPeriods} onInput={linkstate(this, 'inputMedicine.retentionPeriods')} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='productNumber' value={inputMedicine.productNumber} onInput={linkstate(this, 'inputMedicine.productNumber')} /></td>
                                                        <td>
                                                            <nobr>
                                                                <button class='btn btn-primary btn-sm py-1 rounded-pill' onClick={this.onClickAddDrugToJournal}>
                                                                    <i class='fa-solid fa-plus mr-1' />
                                                                    <Text id='input.add'>Add</Text>
                                                                </button>
                                                            </nobr>
                                                        </td>
                                                    </tr>
                                                </>}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <xmp>{JSON.stringify(selectedItem, null, 2)}</xmp> */}

                            </div>
                        </div>
                        {/* <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.journals'>Journals</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                Report for today's journals
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        );
    }
}

export default AddMedicine;
