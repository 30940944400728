import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import versionData from '../../version.json';

import MenuList from './list';

import localUtil from '../../lib/util';

@observer
class Menu extends Component {
	constructor(props) {
        super(props);
        const { appState, userStore } = props.stores;
        const { isDevelopment, opts = {} } = appState;
        const { customer, user, isAdmin } = userStore;
        const { featureFlags = {} } = customer;
        const { isMediaWorker, isSoknadsguideadmin, isApproved } = user;

        this.state = {
            menuLinks: [
                {
                    name: <Text id='menu.main-content'>Main menu</Text>,
                    // help: <Text id='menu.add-new-content-help'>Choose what kind of content you want to create.</Text>,
                    links: [
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.admin'>Admin</Text>,
                            href: '/admin/',
                            icon: 'fa-solid fa-user-police',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.import'>API Imports</Text>,
                            href: '/admin/imports',
                            icon: 'fa-solid fa-user-police',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.development'>Development</Text>,
                            href: '/admin/development',
                            icon: 'fa-solid fa-user-police',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.errors'>Errors</Text>,
                            href: '/errors/',
                            icon: 'fa-solid fa-bug',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.apilogger'>API logger</Text>,
                            href: '/apilogger/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.dyrejournal-admin'>Dyrejournal Admin</Text>,
                            href: '/dyrejournal-admin/',
                            icon: 'fa-solid fa-user-police',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.querylogger'>Query logger</Text>,
                            href: '/querylogger/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.smslist'>SMS List</Text>,
                            href: '/sms/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.mailsentlist'>mailsent List</Text>,
                            href: '/mailsent/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.allGpsDevices'>All GPS Devices</Text>,
                            href: '/allgpsdevices/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.videostreaming'>Video streaming</Text>,
                            href: '/videostreaming/',
                            icon: 'fa-solid fa-list',
                        },
                        {
                            name: <Text id='menu.my-page'>My page</Text>,
                            href: '/users/profile',
                            icon: 'fa-solid fa-user-vneck',
                        },
                        {
                            hasToBeAdmin: !(opts.showDyrejournal && isApproved),
                            name: <Text id='menu.calendar'>Calendar</Text>,
                            href: '/calendar/',
                            icon: 'fa-regular fa-calendar',
                        },
                        {
                            // hasToBeAdmin: !opts.showDyrejournal,
                            hasToBeAdmin: !(featureFlags.dyreid && isApproved),
                            name: <Text id='menu.dyrejournal-clinic'>Dyrejournal - clinic</Text>,
                            href: '/dyrejournal-clinic/',
                            icon: 'fa-regular fa-hospital',
                        },
                        {
                            hasToBeAdmin: !(opts.showDyrejournal && isApproved),
                            name: <Text id='menu.dyrejournal-sale'>Dyrejournal - sale</Text>,
                            href: '/dyrejournal-sale/',
                            icon: 'fa-regular fa-calendar',
                        },
                        {
                            hasToBeAdmin: !(opts.showDyrejournal && isApproved),
                            name: <Text id='menu.dyrejournal-payments'>Dyrejournal - payments</Text>,
                            href: '/dyrejournal-payments/',
                            icon: 'fa-regular fa-money-bill',
                        },
                        {
                            hasToBeAdmin: !(opts.showDyrejournal && isApproved),
                            name: <Text id='menu.dyrejournal-sms'>Dyrejournal - SMS list</Text>,
                            href: '/dyrejournal-sms/',
                            icon: 'fa-regular fa-list',
                        },
                        {
                            hasToBeAdmin: !(opts.showDyrejournal && isApproved),
                            name: <Text id='menu.dyrejournal-mailsent'>Dyrejournal - Mail Sent list</Text>,
                            href: '/dyrejournal-mailsent/',
                            icon: 'fa-regular fa-list',
                        },
                        {
                            hasToBeAdmin: !(opts.showDyrejournal && isApproved),
                            name: <Text id='menu.dyrejournal-files'>Dyrejournal - Files</Text>,
                            href: '/dyrejournal-files/',
                            icon: 'fa-regular fa-files',
                        },
                        {
                            hasToBeAdmin: !(opts.showDyrejournal && isApproved),
                            name: <Text id='menu.dyrejournal-contacts'>Dyrejournal - Contacts</Text>,
                            href: '/dyrejournal-contacts/',
                            icon: 'fa-regular fa-address-book',
                        },
                        {
                            hasToBeAdmin: !isMediaWorker,
                            name: <Text id='menu.media'>Media</Text>,
                            href: '/media/',
                            icon: 'fa-regular fa-images',
                        },
                        {
                            hasToBeAdmin: !opts.showTimeTracker,
                            name: <Text id='menu.timetracker'>Timetracker</Text>,
                            href: '/timetracker/',
                            icon: 'fa-regular fa-stopwatch',
                        },
                        {
                            hasToBeAdmin: !opts.showRace,
                            name: <Text id='menu.race'>Race</Text>,
                            href: '/race/',
                            icon: 'fa-regular fa-flag-checkered',
                        },
                        {
                            hasToHaveCordova: true,
                            name: <Text id='menu.create-gps-tracking'>GPS Tracking</Text>,
                            href: '/workouts/tracking/',
                            icon: 'fa-solid fa-location-arrow',
                        },
                        {
                            hasToBeAdmin: !opts.showInstallation,
                            name: <Text id='menu.installations'>Installations</Text>,
                            href: '/',
                            icon: 'fa-solid fa-house',
                        },
                        {
                            hasToBeAdmin: !opts.showPart,
                            name: <Text id='menu.parts'>Parts</Text>,
                            href: '/parts',
                            icon: 'fa-solid fa-screwdriver-wrench',
                        },
                        {
                            hasToBeAdmin: !opts.showInspections,
                            name: <Text id='menu.inspections'>Inspections</Text>,
                            href: '/inspections',
                            icon: 'fa-solid fa-user-magnifying-glass',
                            type: 'inspections',
                        },
                        {
                            hasToBeAdmin: !(window.location.hostname === 'dokkapk.no'),
                            name: <Text id='menu.shoting-convention'>Shooting convention</Text>,
                            href: '/convention',
                            icon: 'fa-duotone fa-bullseye-pointer',
                        },
                        {
                            hasToBeAdmin: !(window.location.hostname === 'dokkapk.no'),
                            name: <Text id='menu.shot-calc'>Shooting calculator</Text>,
                            href: '/shot-calc',
                            icon: 'fa-solid fa-gun',
                        },
                        {
                            hasToBeAdmin: !(window.location.hostname === 'dokkapk.no'),
                            name: <Text id='menu.shot-trainer'>Shooting trainer</Text>,
                            href: '/shot-trainer',
                            icon: 'fa-solid fa-bullseye',
                        },
                        {
                            hasToBeAdmin: !(opts.showDyrejournal && isApproved),
                            name: <Text id='menu.texttemplates'>Text Templates</Text>,
                            href: '/texttemplates',
                            icon: 'fa-solid fa-text-size',
                        },
                        {
                            hasToBeAdmin: !isSoknadsguideadmin,
                            name: <Text id='menu.texttemplates'>Text Templates</Text>,
                            href: '/texttemplates',
                            icon: 'fa-solid fa-text-size',
                        },
                        {
                            hasToBeAdmin: false,
                            name: <Text id='menu.news'>News</Text>,
                            href: '/news',
                            icon: 'fa-solid fa-newspaper',
                            type: 'news',
                        },
                        {
                            name: <Text id='menu.help'>Help</Text>,
                            func: this.showHelp,
                            icon: 'fa-duotone fa-life-ring text-',
                        },
                        {
                            hasToBeAdmin: true,
                            name: <Text id='menu.report-bug'>Report Bug</Text>,
                            func: this.reportBug,
                            icon: 'fa-solid fa-bug',
                        },
                        {
                            name: <Text id='menu.reload'>Reload</Text>,
                            func: this.reloadApp,
                            icon: 'fa-solid fa-arrows-rotate',
                        },
                        {
                            name: <Text id='menu.logout'>Logout</Text>,
                            href: '/logout',
                            icon: 'fa-solid fa-right-from-bracket',
                        },
                    ],
                },
            ],
            menuLinksNotLoggedIn: [
                {
                    name: <Text id='menu.main-content'>Main menu</Text>,
                    // help: <Text id='menu.add-new-content-help'>Choose what kind of content you want to create.</Text>,
                    links: [
                        {
                            name: <Text id='menu.help'>Help</Text>,
                            func: this.showHelp,
                            icon: 'fa-duotone fa-life-ring',
                        },
                        {
                            hasToBeAdmin: !isMediaWorker,
                            name: <Text id='menu.media'>Media</Text>,
                            href: '/media/',
                            icon: 'fa-regular fa-images',
                        },
                        {
                            hasToBeAdmin: false,
                            name: <Text id='menu.news'>News</Text>,
                            href: '/news',
                            icon: 'fa-solid fa-newspaper',
                            type: 'news',
                        },
                        {
                            hasToBeAdmin: !opts.showRace,
                            name: <Text id='menu.race'>Race</Text>,
                            href: '/race/',
                            icon: 'fa-regular fa-flag-checkered',
                        },
                        {
                            name: <Text id='menu.reload'>Reload</Text>,
                            func: this.reloadApp,
                            icon: 'fa-solid fa-arrows-rotate',
                        },
                        {
                            name: <Text id='menu.login'>Login</Text>,
                            func: this.gotoLogin,
                            icon: 'fa-solid fa-right-to-bracket',
                        },
                    ],
                },
            ],
        };
    }

    gotoLogin = () => {
        const { appState } = this.props.stores;
        const { path } = appState;
        appState.setWantedPage(path);
        route('/login');
    }

    reloadApp = () => {
        window.location.reload(true);
    }

    reportBug = () => {
        const { drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('bugReport', {
			height: drawerHeightMedium,
        }, drawerLevel + 1);
    }

    showHelp = () => {
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMediumLarge } = appState;
        appState.toggleDrawer(false, drawerLevel);
        appState.openDrawer('help', {
			height: drawerHeightMediumLarge,
        }, drawerLevel + 1);
    }

    onClickViewAnimal = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.animal-line').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore, animalStore } = this.props.stores;
		const { drawerHeightLarge } = appState;

		appState.openDrawer('viewAnimal', {
			height: drawerHeightLarge,
            isNew: false,
            hideAdmin: true,
            id: parseInt(id, 10),
            callback: () => {
                // console.log('onClickViewAnimal callback');
                const { animal } = animalStore;
                const { newSale, visitorAnimals } = saleStore;
                const { animals } = newSale;
                const idx = animals.findIndex(a => a.id === animal.id);
                if (idx > -1) {
                    saleStore.updateObjectKeyArrValue('newSale', 'animals', idx, animal);
                }
                const idx2 = visitorAnimals.findIndex(a => a.id === animal.id);
                if (idx2 > -1) {
                    saleStore.updateKeyArrValue('visitorAnimals', idx2, animal);
                }
            },
		}, drawerLevel + 1);
    }

    selectView = (e) => {
        const { appState } = this.props.stores;
        const { type } = e.target.closest('button').dataset;
        appState.setViewKey('clinic', type);
    }

    toggleAdminFields = () => {
        const { appState } = this.props.stores;
        const { showAdminFields } = appState;
        appState.setShowAdminFields(!showAdminFields);
    }

    loadAll = async () => {
        const { appState, animalStore } = this.props.stores;
        const { isDevelopment, opts = {} } = appState;
        if ((isDevelopment || opts.showDyrejournal)) {
            animalStore.load({ query: { isAdmittedToClinic: true }, addData: ['owners'] });
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { menuLinks, menuLinksNotLoggedIn } = this.state;
        const { drawerLevel } = this.props;
        const { appState, userStore, animalStore } = this.props.stores;
        const { isCordova, languageList, language, opts = {}, isDevelopment, view = {}, showAdminFields } = appState;
        const { user, isAdmin, saved, customer } = userStore;
        const { language: userLanguage } = user;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { animals, owners } = animalStore
        if (!user.id) {
            return (<>
                <div class='bg-light d-flex flex-column justify-content-start pt-4' style='min-height: 100%;'>
                    <MenuList stores={this.props.stores} menus={menuLinksNotLoggedIn} drawerLevel={drawerLevel} />
                    <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
                        {languageList.map(lang => {
                            return (<>
                                <button
                                    type='button'
                                    class={`btn btn-link ${language === lang[0] ? 'text-primary font-weight-bold' : 'text-muted'}`}
                                    onClick={() => appState.setLanguage(lang[0])}
                                >
                                    {lang[2]} {lang[1]}
                                </button>
                            </>);

                        })}
                    </div>
                </div>
            </>);
        }

        return (
            <div class='bg-light d-flex flex-column justify-content-start pt-4' style='min-height: 100%;'>

                {(isDevelopment || opts.showDyrejournal) && <>
                    <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                        <small class='text-uppercase'>
                            <Text id='dyrejournal.clinic'>Your clinic</Text>
                        </small>
                    </div>
                    <div
                        class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-4 d-flex flex-column`}
                        style={`
                            font-size: 18px !important;
                            border-radius: 15px;
                        `}
                    >
                        {/* <div class='position-absolute d-flex flex-nowrap' style='top: 17px; right: 50px;'>
                            <button type='button' class={`btn btn-${view.clinic === 'normal' ? 'primary' : 'outline-secondary'} rounded-pill-left`} onClick={this.selectView} data-type={'normal'}>
                                <i class='fa-duotone fa-rabbit' />
                            </button>
                            <button type='button' class={`btn btn-${view.clinic === 'horse' ? 'primary' : 'outline-secondary'} rounded-none`} onClick={this.selectView} data-type={'horse'}>
                                <i class='fa-duotone fa-horse' />
                            </button>
                            <button type='button' class={`btn btn-${view.clinic === 'cow' ? 'primary' : 'outline-secondary'} rounded-pill-right`} onClick={this.selectView} data-type={'cow'}>
                                <i class='fa-duotone fa-cow' />
                            </button>
                        </div> */}

                        <div class='d-flex flex-row flex-wrap justify-content-center align-items-center'>
                            {animals.length > 0 && animals.map(animal => {
                                const owner = owners && owners.length > 0 ? owners.find(e => e.id === animal.owner) : {};
                                const image = localUtil.getImage({ user: animal });
                                return (<>
                                    <div
                                        class='d-flex flex-column bg-light rounded-lg px-2 py-2 m-2 animal-line'
                                        data-id={animal.id}
                                        onClick={this.onClickViewAnimal}
                                    >
                                        <div class='d-flex flex-row justify-content-center align-items-center'>
                                            <div
                                                class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-circle d-flex justify-content-center align-items-center font-weight-lighter imageRounded imageRoundedMediumLarge`}
                                                style={`${image ? `background-image: url('${image}'); background-size: cover;` : ''}`}
                                                // style='width: 80px; height: 80px; font-size: 2.5em;'
                                            >
                                                {!image && <>
                                                    <span style='font-size: 33px;'>{animal.name[0]}</span>
                                                 </>}
                                            </div>
                                        </div>
                                        <div class='d-flex justify-content-between'>
                                            <div>{animal.name}</div>
                                            {animal.birth && <div>{util.age(animal.birth)}</div>}
                                        </div>
                                        <div class='font-weight-lighter'>
                                            ({animal.species}/{animal.breed})
                                        </div>
                                        <div>
                                            {owner?.firstname} {owner?.lastname}
                                        </div>
                                        <div class='font-weight-lighter'>
                                            {owner?.cellphone}
                                        </div>
                                    </div>
                                </>);
                            })}
                        </div>
                    </div>
                </>}

                <MenuList stores={this.props.stores} menus={menuLinks} drawerLevel={drawerLevel} />

                <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
                    {languageList.map(lang => {
                        return (<>
                            <button
                                type='button'
                                class={`btn btn-link ${userLanguage === lang[0] ? 'text-primary font-weight-bold' : 'text-muted'}`}
                                onClick={() => userStore.setLanguage(lang[0])}
                            >
                                {lang[2]} {lang[1]}
                            </button>
                        </>);

                    })}
                </div>
                {isAdmin && <>
                    <div class='d-flex flex-row justify-content-center align-items-center mt-1'>
                        <span
                            class={`badge badge-pill badge-${showAdminFields ? 'danger' : 'light'} font-weight-bold mr-2 mt-2`}
                            onClick={this.toggleAdminFields}
                        >Show Admin</span>
                    </div>
                </>}
                <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
                    <small>
                        Build: {versionData.build}, <span class='font-weight-lighter'>{util.isoDate(versionData.date)}</span>
                    </small>
                </div>
            </div>
        );
    }
}

export default Menu;
